import React, { useState } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import ContentWrapper from 'components/layout/ContentWrapper'
import {
  Row, Col, Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { useMountEffect } from 'lib/hooks'
import { deserializeFromQueryString } from 'lib/utils'
import { Swal } from 'lib/components'
import { generalConstant as gc } from 'config/constants'
import CampaignList from 'components/parts/campaign/CampaignList'
import CampaignRefineSearchForm from 'components/parts/campaign/CampaignRefineSearchForm'
import {
  abandonedCampaignAction, completedCampaignAction, contextualCampaignAction, campaignCopyAction,
} from 'actions'
import { useCookies } from 'react-cookie'

export const CampaignListPage = ({
  dispatch, location, company, campaigns
}) => {
  const [cookies, setCookie] = useCookies([
    'campaignListFilterQuery',
  ])
  const [copyCampaignIds, setCopyCampaignIds] = useState([])
  const handleChangeCopyCampaignIds = e => {
    const targetId = e.target.value
    if (e.target.checked) {
      setCopyCampaignIds([
        ...copyCampaignIds,
        targetId,
      ])
    } else {
      setCopyCampaignIds(copyCampaignIds.filter(id => id !== targetId))
    }
  }
  useMountEffect(() => {
    dispatch(abandonedCampaignAction.fetchAbandonedCampaigns({ show_finished: false }))
    dispatch(completedCampaignAction.fetchCompletedCampaigns({ show_finished: false }))
    dispatch(contextualCampaignAction.fetchContextualCampaigns({ show_finished: false }))
  })
  const queryObj = deserializeFromQueryString(location.search) || deserializeFromQueryString(cookies.campaignListFilterQuery) || {}
  const page = Number(queryObj.page) || 1
  const handleClickCampaignCopy = () => {
    const numericCopyCampaignIds = copyCampaignIds.map((id) => Number(id))
    // コピー対象のキャンペーンを取得
    const copyCampaigns = campaigns.filter((campaign) => numericCopyCampaignIds.includes(campaign.id))
    // 後続キャンペーン(trigger_campaign_idを持つ)がコピー対象に含まれているかチェック
    const followingCampaigns = copyCampaigns.filter((campaign) => campaign.triggerCampaignId)
    // 後続キャンペーンの先行キャンペーンもコピー対象に含まれているかチェック、含まれていない場合は後続キャンペーン名と先行キャンペーン名をerrorMessagesに追加
    let errorMessages = []
    followingCampaigns.forEach((followingCampaign) => {
      if (!numericCopyCampaignIds.includes(followingCampaign.triggerCampaignId)) {
        const triggerCampaign = campaigns.find((campaign) => campaign.id === followingCampaign.triggerCampaignId)
        errorMessages.push(`「${followingCampaign.name}」の先行キャンペーン「${triggerCampaign.name}」がコピー対象に含まれていません。`)
      }
    })
    // エラーメッセージがある場合はアラートを表示して処理を中断
    if (errorMessages.length > 0) {
      alert(errorMessages.join('\n'))
      return
    }
    dispatch(campaignCopyAction.fetchCampaignCopy({ campaign_ids: copyCampaignIds }))
  }
  const confirmCampaignCopySwalOptions = (parentCompany) => {
    return {
      title: '本番アカウントへコピーしますか？',
      text: `本番アカウント名: ${parentCompany.name} \nシリアル: ${parentCompany.serial} \n\n実行後、この操作は取り消せません。`,
      icon: 'warning',
      dangerMode: false,
      closeModal: true,
      closeOnClickOutside: true,
      closeOnEsc: true,
      buttons: ['キャンセル', '実行'],
    }
  }

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>キャンペーン一覧</div>
        <div className="ml-3">
          <UncontrolledDropdown>
            <DropdownToggle caret>新規作成</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => { dispatch(push('/campaign_template_list/abandoned')) }}
              >
放棄系キャンペーン
              </DropdownItem>
              <DropdownItem
                onClick={() => { dispatch(push('/campaign_template_list/completed')) }}
              >
完了系キャンペーン
              </DropdownItem>
              <DropdownItem
                onClick={() => { dispatch(push('/campaign_template_list/contextual')) }}
              >
商品連動キャンペーン
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        { company?.testAccount
          && !!company?.parentCompany
          && (
            <Swal
              className="ml-3"
              options={confirmCampaignCopySwalOptions(company.parentCompany)}
              color="primary"
              callback={(isConfirm) => { isConfirm && handleClickCampaignCopy() }}
              disabled={copyCampaignIds.length === 0}
            >
              本番アカウントへコピー
            </Swal>
          )
        }
        {
          company?.testAccount
          && !!company?.parentCompany
          && copyCampaignIds.length > 0
          && (
            <div className="ml-3">
              <p className="mb-0" style={{fontSize: '10px'}}>コピー先</p>
              <p className="mb-0" style={{fontSize: '10px'}}>本番アカウント名：{ company.parentCompany.name }</p>
              <p className="mb-0" style={{fontSize: '10px'}}>シリアル：{ company.parentCompany.serial }</p>
            </div>
          )
        }
      </div>
      <Row>
        <Col xl={9}>
          <Card className="card-default">
            <CampaignList
              limit={gc.campaignItemsLimit}
              offset={(page - 1) * gc.campaignItemsLimit}
              campaignType={queryObj.campaign}
              deliveryStatus={queryObj.deliveryStatus}
              onChangeCopyCampaignIds={handleChangeCopyCampaignIds}
            />
          </Card>
        </Col>
        <Col xl={3}>
          <Card className="card-default">
            <CampaignRefineSearchForm cookies={cookies} setCookie={setCookie} />
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

const select = ({
  abandonedCampaign, completedCampaign, contextualCampaign, company,
}) => {
  const abanItems = abandonedCampaign.items
  const compItems = completedCampaign.items
  const contItems = contextualCampaign.items
  return {
    campaigns: [...abanItems, ...compItems, ...contItems],
    company: { ...company.item },
  }
}
export default connect(select)(CampaignListPage)
